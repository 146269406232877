import React, { Component } from "react";

import { Modal, Table } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import { amountDivide, formatDate } from "../../util";
import string from "../../string";
import SelectBox from "../common/SelectBox";

class RollupLogHoldSelect extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    rewardType: "ALL"
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    const { pageType, page } = this.props;

    pageType === "user"
      ? page === "hold"
        ? this.getRollupHoldLogSelect({ pageNum: pager.current })
        : this.getUserHoldLogList({ pageNum: pager.current })
      : this.getRollupLogHoldSelect({ pageNum: pager.current });
  };

  // 검색 대상 설정
  rewardTypeHandleChange = (value) => {
    this.setState(
      {
        rewardType: value
      },
      () => {
        const { page } = this.props;

        page === "user" && this.getUserHoldLogList();
        page === "rollup" && this.getRollupLogHoldSelect();
        page === "hold" && this.getRollupHoldLogSelect();
      }
    );
  };

  // 보류내역 상세 - 정렬 시 날짜기준
  getRollupHoldLogSelect = (params = {}) => {
    const { recommendCode, hold } = this.props;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getRollupHoldLogSelect,
      [10, pageNum, recommendCode, this.state.rewardType, hold],
      {}
    )
      .then((res) => {
        // console.log(`reward res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  // 롤업내역 상세 - 정렬 시 단계기준
  getRollupLogHoldSelect = (params = {}) => {
    const { idx } = this.props;
    const { rewardType } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getRollupLogHoldSelect, [10, pageNum, idx, rewardType], {})
      .then((res) => {
        // console.log(`rollup res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  // 회원관리 보류내역 상세 - 정렬 시 날짜기준
  getUserHoldLogList = (params = {}) => {
    const { page, recommendCode, hold } = this.props;
    let pageNum = params.pageNum || 1;
    let url = "";
    if (page === "user") url = httpUrl.getUserHoldLogList;
    if (page === "rollup") url = httpUrl.getRollupUserHoldLogList;
    if (page === "swap") url = httpUrl.getSwapHoldLogList;

    httpGet(url, [10, pageNum, recommendCode, this.state.rewardType, hold], {})
      .then((res) => {
        // console.log(`user hold res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
    const { pageType, page } = this.props;
    pageType === "user" &&
      this.setState(
        {
          rewardType: this.props.type
        },
        () => {
          page === "hold"
            ? this.getRollupHoldLogSelect()
            : this.getUserHoldLogList();
        }
      );
    page === "rollup" && this.getRollupLogHoldSelect();
  }

  render() {
    const { visible, onCancel, page, type, hold } = this.props;
    const { rewardType, list, pagination } = this.state;

    const columns = [
      {
        key: "createData",
        title: "날짜",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "TYPE",
        dataIndex: "rewardType",
        className: "text-center",
        render: (data) => <div>{string.rollupTypeString[data]}</div>
      },
      {
        title: "FROM",
        dataIndex: "from",
        className: "text-center",
        render: (data, row) => (
          <div>
            {row.fromId} ({data})
          </div>
        )
      },
      {
        title: "TO",
        dataIndex: "to",
        className: "text-center",
        render: (data, row) => (
          <div>
            {row.toId} ({data})
          </div>
        )
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data) => <div>{amountDivide(data)}</div>
      },
      {
        title: "단계",
        dataIndex: "depth",
        className: "text-center"
      },
      {
        title: "기타",
        dataIndex: "holdFlag",
        className: "text-center",
        render: (data) => <div>{string.holdFlagString[data]}</div>
      }
    ];

    return (
      <Modal
        visible={visible}
        title={`${
          type === "ROLL_UP"
            ? "롤업"
            : type === "REC_ROLL_UP"
            ? "추천 롤업"
            : "지금 제외"
        } 보류 정보`}
        onCancel={onCancel}
        footer={null}
        style={{ minWidth: "1200px" }}
      >
        {(page === "rollup" || hold === "HOLD") && (
          <div className="flex-row flex-center m-b-10 flex-end">
            <label htmlFor="rewardType">
              TYPE&nbsp;
              <SelectBox
                value={rewardType}
                style={{ width: "160px", marginRight: "10px" }}
                code={string.rollupTypeSelectString}
                codeString={string.rollupTypeSelectString}
                onChange={(value) => {
                  if (value !== rewardType) {
                    this.rewardTypeHandleChange(value);
                  }
                }}
              />
            </label>
          </div>
        )}
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}

export default RollupLogHoldSelect;
