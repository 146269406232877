import React, { Component } from "react";
import color from "../../color";

class EventStatistics extends Component {
  render() {
    const { title, data } = this.props;
    return (
      <div className="level-title" style={{ color: color.darkgray }}>
        <label style={{ fontSize: 18 }}>{title}</label>
        <div
          className="flex-row flex-center justify-center"
          style={{ fontSize: 26 }}
        >
          {data}
        </div>
      </div>
    );
  }
}

export default EventStatistics;
