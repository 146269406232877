import React, { Component } from "react";

import { Card } from "antd";

import { amountDivide, numberFormat } from "../../util";
import StatisticsDiv from "./StatisticsDiv";

class VirtualAccountDepositCard extends Component {
  render() {
    const { title, data } = this.props;
    return (
      <Card.Grid
        hoverable={false}
        style={{
          border: "0px dotted #A6A6A6",
          borderRadius: 20,
          marginRight: 20,
          width: 370
        }}
      >
        <div className="font text-left m-b-10">{title}</div>
        <StatisticsDiv
          title="총 건수"
          data={numberFormat(data.count)}
          style={{ fontWeight: "normal" }}
        />
        <StatisticsDiv
          title="총 수량"
          data={amountDivide(data.amount)}
          style={{ fontWeight: "normal" }}
        />
        <StatisticsDiv
          title="오늘 건수"
          data={numberFormat(data.todayCount)}
          style={{ fontWeight: "normal" }}
        />
        <StatisticsDiv
          title="오늘 수량"
          data={amountDivide(data.todayAmount)}
          style={{ fontWeight: "normal" }}
        />
      </Card.Grid>
    );
  }
}

export default VirtualAccountDepositCard;
