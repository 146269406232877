import React, { Component } from "react";

import { Input, Table } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import { numberFormat } from "../../util";
import SwapCard from "../../components/swap/SwapCard";
import UserDetail from "../../components/user/UserDetail";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";

const Search = Input.Search;
class SwapLog extends Component {
  state = {
    list: [],
    pagination: {
      current: 0,
      total: 0
    },
    statusType: "ALL",
    searchText: "",
    startDate: "",
    endDate: ""
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getSwapLogList({ pageNum: pager.current });
  };

  // 등급 검색 설정
  statusTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(value)}`);
    this.setState({
      statusType: value
    });
  };
  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getSwapLogList();
      }
    );
  };

  getSwapLogList = (params = {}) => {
    const { statusType, searchText, startDate, endDate } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getSwapLogList,
      [10, pageNum, statusType, searchText, startDate, endDate],
      {}
    )
      .then((res) => {
        // console.log(`res swap = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getSwapLogList();
  }

  render() {
    const { visible, userData, statusType, list, pagination } = this.state;

    const columns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "ID (이름)",
        dataIndex: "id",
        className: "text-center",
        render: (data, row) => (
          <div
            className="pointer"
            onClick={() => {
              this.setState({
                visible: true,
                userData: {
                  id: row.id,
                  idx: row.userIdx,
                  name: row.name,
                  recommendCode: row.recommendCode
                }
              });
            }}
          >
            {`${data} (${row.name})`}
          </div>
        )
      },
      {
        title: "교환 전",
        dataIndex: "coinType",
        className: "text-center",
        render: (data, row) => (
          <SwapCard
            data={{
              status: row.status,
              date: row.createDate,
              amount: row.amount,
              coinType: row.coinType,
              currency: row.currency,
              txId: row.txId
            }}
          />
        )
      },
      {
        title: "교환 후",
        dataIndex: "swapStatus",
        className: "text-center",
        render: (data, row) => (
          <SwapCard
            data={{
              status: row.swapStatus,
              date: row.swapDate,
              amount: row.swapAmount,
              coinType: row.swapCoinType,
              currency: row.swapCurrency,
              txId: row.swapTxId
            }}
          />
        )
      }
    ];

    return (
      <div>
        {visible && (
          <UserDetail
            page="swap"
            visible={visible}
            userData={userData}
            onCancel={() => {
              this.setState({ visible: false });
            }}
          />
        )}
        <div className="title">스왑 내역</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <label htmlFor="statusType" className="m-r-10">
            상태&nbsp;
            <SelectBox
              value={statusType}
              style={{ width: "160px" }}
              code={string.requestLogCode}
              codeString={string.swapLogString}
              onChange={(value) => {
                if (value !== statusType) {
                  this.statusTypeHandleChange(value);
                }
              }}
            />
          </label>
          <Search
            placeholder="아이디를 입력해주세요."
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: 300 }}
          />
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default SwapLog;
