import React, { Component } from "react";
import { Card } from "antd";
import { amountDivide, numberFormat } from "../../util";

class RollupCard extends Component {
  state = {};

  render() {
    const { countTitle, count, amountTitle, amount } = this.props;

    return (
      <Card.Grid
        hoverable={false}
        style={{
          border: "0px dotted #A6A6A6",
          borderRadius: 20,
          marginRight: 20
        }}
      >
        <div className="flex-row flex-center flex-sa">
          <div className="font text-left" style={{ width: 200 }}>
            {countTitle}
          </div>
          <div className="font text-right" style={{ width: 200 }}>
            {numberFormat(count)}
          </div>
        </div>
        <div className="flex-row flex-center flex-sa ">
          <div className="font text-left" style={{ width: 200 }}>
            {amountTitle}
          </div>
          <div className="font text-right" style={{ width: 200 }}>
            {amountDivide(amount)}
          </div>
        </div>
      </Card.Grid>
    );
  }
}

export default RollupCard;
