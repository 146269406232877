import React, { Component } from "react";

import { Icon } from "antd";

import string from "../../string";
import color from "../../color";

class ChartCard extends Component {
  componentDidMount() {}

  render() {
    const { onClick, className, data, style } = this.props;
    return (
      <div
        className="inline"
        style={{
          ...style,
          padding: 10,
          paddingLeft: 15,
          paddingRight: 15,
          border: "3px solid",
          borderRadius: 20,
          borderColor:
            data.level === 0
              ? color.m
              : data.level === 1
              ? color.m1
              : data.level === 2
              ? color.m2
              : data.level === 3
              ? color.m4
              : data.level === 4
              ? color.gold
              : data.level === 5
              ? color.diamond
              : color.penton
        }}
      >
        <div onClick={onClick} className={className}>
          <div className="flex-row flex-center flex-sb m-b-10">
            <div
              style={{
                fontWeight: "bold",
                color:
                  data.level === 0
                    ? color.m
                    : data.level === 1
                    ? color.m1
                    : data.level === 2
                    ? color.m2
                    : data.level === 3
                    ? color.m4
                    : data.level === 4
                    ? color.gold
                    : data.level === 5
                    ? color.diamond
                    : color.penton
              }}
            >
              {string.userLevelCode[data.level]}
            </div>
            {data.avatar === 1 && (
              <Icon
                type="robot"
                style={{
                  fontSize: 20,
                  color:
                    data.level === 0
                      ? color.m
                      : data.level === 1
                      ? color.m1
                      : data.level === 2
                      ? color.m2
                      : data.level === 3
                      ? color.m4
                      : data.level === 4
                      ? color.gold
                      : data.level === 5
                      ? color.diamond
                      : color.penton
                }}
              />
            )}
          </div>
          <div
            style={{
              borderBottom: "1px solid",
              borderBottomColor:
                data.level === 0
                  ? color.m
                  : data.level === 1
                  ? color.m1
                  : data.level === 2
                  ? color.m2
                  : data.level === 3
                  ? color.m4
                  : data.level === 4
                  ? color.gold
                  : data.level === 5
                  ? color.diamond
                  : color.penton
            }}
          />
          <div
            className="m-t-10 m-b-10"
            style={{
              minWidth: 180,
              fontWeight: "bold",
              color:
                data.level === 0
                  ? color.m
                  : data.level === 1
                  ? color.m1
                  : data.level === 2
                  ? color.m2
                  : data.level === 3
                  ? color.m4
                  : data.level === 4
                  ? color.gold
                  : data.level === 5
                  ? color.diamond
                  : color.penton
            }}
          >
            {data.id}
          </div>
        </div>
        <div className="flex-row flex-center justify-center font-12">
          <div className="text-right m-r-10" style={{ color: color.gray }}>
            <div>이름</div>
            <div>추천인</div>
            <div>산하</div>
            <div>직추</div>
          </div>
          <div className="text-right" style={{ color: color.black }}>
            <div>
              {data.name}({data.recommendCode})
            </div>
            <div>
              {data.recommendName}({data.recommend})
            </div>
            <div>
              {data.leftCnt} | {data.rightCnt}
            </div>
            <div>
              {data.recLeftCnt} | {data.recRightCnt}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChartCard;
