import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Button, Input, Select, Table } from "antd";
import moment from "moment";

import { httpGet, httpPost, httpUrl } from "../../api/httpClient";
import {
  checkCoinName,
  checkData,
  coinDivideFormat,
  formatDate,
  formatDateSecond,
  numberFormat,
  releaseTimeDate
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import { showAlert } from "../../components/common/AlertModal";
import DailyLockModal from "../../components/coin/DailyLockModal";

const Search = Input.Search;
const Option = Select.Option;
class DecentralDailyLock extends Component {
  state = {
    lockList: [],
    coinList: [],
    coinType: 0,
    lockSearchType: "ID",
    lockSearchText: "",

    visible: false,

    list: [],
    pagination: {
      current: 0,
      total: 0
    },
    lockType: "ALL",
    statusType: "ALL",
    searchType: "ADDRESS",
    searchText: "",

    isLoaded: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getLockRequestList({ pageNum: pager.current });
    }
  };

  // 코인 종류 선택
  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 검색
  onLockSearch = (value) => {
    this.setState(
      {
        lockSearchText: value
      },
      () => this.getDailyLockList()
    );
  };

  // 검색
  onRequestSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => this.getDailyLockRequestList()
    );
  };

  // LOCK 해지
  unLock = (row) => {
    const { coinType } = this.state;
    let body = { ...row, releaseDate: row.releaseTime, coinType };
    // console.log(`body =${JSON.stringify(body, null, 4)}`);

    if (global.confirm("해제하시겠습니까?"))
      httpPost(httpUrl.unDailyLock, [], body)
        .then((res) => {
          // console.log(JSON.stringify(res, null, 4));
          if (res.data) {
            showAlert("unlock");
            this.getDailyLockList();
            this.getLockRequestList();
          }
        })
        .catch((e) => {});
  };

  // LOCK 목록
  getDailyLockList = () => {
    const { coinType, lockSearchType, lockSearchText } = this.state;

    httpGet(
      httpUrl.getDailyLockList,
      [coinType, lockSearchType, lockSearchText],
      {}
    )
      .then((res) => {
        // console.log(`-- LOCK LIST --`);
        // console.log(`res =${JSON.stringify(res.data, null, 4)}`);
        this.setState({
          // lockList: res.data.list
          lockList: res.data.list.sort((x, y) => y.releaseTime - x.releaseTime),
          listPending: res.data.pending
        });
      })
      .catch((e) => {});
  };

  // LOCK REQUEST 목록
  getDailyLockRequestList = (params = {}) => {
    const { lockType, statusType, searchType, searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getDailyLockRequestList,
      [10, pageNum, lockType, statusType, searchType, searchText],
      {}
    )
      .then((res) => {
        // console.log(`-- DAILY LOCK REQUEST LIST --`);
        // console.log(`res =${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({ list: res.data.list, pagination, isLoaded: false });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    let coinList = reactLocalStorage.getObject("coinList");
    let arr = [];
    coinList.forEach((coin) => {
      if (coin.category != "MAINNET") arr.push({ ...coin });
    });

    this.setState({ coinList: arr, coinType: arr[0].coinType });
    this.getDailyLockRequestList();
  }

  render() {
    const {
      visible,
      coinList,
      lockList,
      listPending,
      coinType,
      lockSearchType,
      lockSearchText,
      lockType,
      statusType,
      searchType,
      list,
      pagination
    } = this.state;

    const lockColumns = [
      {
        key: "lockIdx",
        title: "Lock번호",
        dataIndex: "lockIdx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "해제일",
        dataIndex: "releaseTime",
        className: "text-center",
        render: (data) => <div>{releaseTimeDate(data)}</div>
      },
      {
        title: "해제기간(일)",
        dataIndex: "releaseDays",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "주소",
        dataIndex: "address",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data) => <div>{coinDivideFormat(data, coinType)}</div>
      },
      {
        title: "마지막 날 수량",
        dataIndex: "lastDayAmount",
        className: "text-center",
        render: (data) => <div>{coinDivideFormat(data, coinType)}</div>
      },
      {
        title: "상태",
        dataIndex: "",
        className: "text-center",
        render: (data, row) => (
          <Button
            disabled={listPending ? true : false}
            onClick={() => {
              this.unLock(row);
            }}
          >
            해지
          </Button>
        )
        // releaseTimeDate(row.releaseTime) >
        // formatDateSecond(
        //   moment()
        //     .endOf("day" - 1)
        //     .toString()
        // ) ? (
        //   <Button
        //     onClick={() => {
        //       this.unLock(row);
        //     }}>
        //     해지
        //   </Button>
        // ) : (
        //   "-"
        // )
      }
    ];
    const columns = [
      {
        key: "idx",
        title: "번호",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => (
          <div className="maxWidth-80">{numberFormat(data)}</div>
        )
      },
      {
        title: "요청일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div className="maxWidth-80">{formatDate(data)}</div>
      },
      {
        title: "코인",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "TYPE",
        dataIndex: "lockType",
        className: "text-center",
        render: (data) => <div>{string.lockRequesetString[data]}</div>
      },
      {
        title: "아이디",
        dataIndex: "id",
        className: "text-center",
        render: (data) => <div>{data ? checkData(data) : "외부"}</div>
      },
      {
        title: "주소",
        dataIndex: "address",
        className: "text-center",
        render: (data) => (
          <div style={{ maxWidth: "250px" }}>{checkData(data)}</div>
        )
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data) => (
          <div className="maxWidth-80">{coinDivideFormat(data, coinType)}</div>
        )
      },
      {
        title: "해제일",
        dataIndex: "releaseDate",
        className: "text-center",
        render: (data) => <div>{releaseTimeDate(data)}</div>
      },
      {
        title: "해제기간(일)",
        dataIndex: "releaseDays",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "txId",
        dataIndex: "txId",
        className: "text-center",
        render: (data) => (
          <div style={{ maxWidth: "250px" }}> {checkData(data)} </div>
        )
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div> {string.requestLogString[data]}</div>
      },
      {
        title: "transferFlag",
        dataIndex: "transferFlag",
        className: "text-center",
        render: (data) => <div>{data ? "TRUE" : "FALSE"}</div>
      }
    ];

    return (
      <div>
        {visible && (
          <DailyLockModal
            visible={visible}
            onCancel={() => {
              this.setState({ visible: false });
            }}
            searchText={lockSearchText}
            lockRefresh={this.getDailyLockList}
            requestRefresh={this.getDailyLockRequestList}
          />
        )}
        <div className="title">DAILY LOCK 관리</div>
        <div className="flex-row flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({ visible: true, type: "create" });
            }}
          >
            생성
          </Button>
          <div>
            <label htmlFor="" style={{ marginRight: "20px" }}>
              코인
              <Select
                style={{ width: "160px", marginLeft: "10px" }}
                value={coinType !== 0 ? coinType : "선택해주세요"}
                onChange={this.handleChangeCoinType}
              >
                {coinList.map((coin) => (
                  <Option key={coin.coinType} value={coin.coinType}>
                    {coin.name}
                  </Option>
                ))}
              </Select>
            </label>
            <label htmlFor="">
              검색기준{" "}
              <SelectBox
                style={{ width: "160px", marginRight: "10px" }}
                value={string.searchTypeString[lockSearchType]}
                code={string.memberSearchTypeCode}
                codeString={string.searchTypeString}
                onChange={(value) => {
                  if (value !== lockSearchType) {
                    this.setState({ lockSearchType: value });
                  }
                }}
              />
              <Search
                placeholder={`${
                  lockSearchType === "ID" ? "아이디" : "주소"
                }를 입력하세요`}
                enterButton="검색"
                allowClear
                onSearch={this.onLockSearch}
                style={{ width: "300px" }}
              />
            </label>
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={lockColumns}
          dataSource={lockList}
          pagination={false}
          rowClassName={(record) => {
            return releaseTimeDate(record.releaseTime) <=
              formatDateSecond(
                moment()
                  .endOf("day" - 1)
                  .toString()
              )
              ? "gray"
              : "";
          }}
        />
        <div style={{ clear: "both", height: "40px" }} />
        <div className="flex-row flex-end m-b-10">
          <label htmlFor="">
            TYPE{" "}
            <SelectBox
              style={{ width: "160px", marginRight: "10px" }}
              value={string.lockRequesetString[lockType]}
              code={string.lockRequestCode}
              codeString={string.lockRequesetString}
              onChange={(value) => {
                if (value !== lockType) {
                  this.setState({ lockType: value, isLoaded: true });
                }
              }}
            />
          </label>
          <label htmlFor="">
            상태{" "}
            <SelectBox
              style={{ width: "160px", marginRight: "10px" }}
              value={string.requestLogString[statusType]}
              code={string.requestLogCode}
              codeString={string.requestLogString}
              onChange={(value) => {
                if (value !== statusType) {
                  this.setState({ statusType: value, isLoaded: true });
                }
              }}
            />
          </label>
          <label htmlFor="">
            검색기준{" "}
            <SelectBox
              style={{ width: "160px", marginRight: "10px" }}
              value={string.lockRequesetSearchString[searchType]}
              code={string.lockRequestSearchCode}
              codeString={string.lockRequesetSearchString}
              onChange={(value) => {
                if (value !== searchType) {
                  this.setState({ searchType: value, isLoaded: true });
                }
              }}
            />
            <Search
              placeholder={`${
                searchType === "ID"
                  ? "아이디"
                  : searchType === "ADDRESS"
                  ? "주소"
                  : "TXID"
              }를 입력하세요`}
              enterButton="검색"
              allowClear
              onSearch={this.onRequestSearch}
              style={{ width: "300px" }}
            />
          </label>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default DecentralDailyLock;
