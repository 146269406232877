import React, { Component } from "react";
import { Tree, TreeNode } from "react-organizational-chart";

import { Icon, Input } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";
import color from "../../color";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import ChartCard from "../../components/chart/ChartCard";

const Search = Input.Search;
class Chart extends Component {
  state = {
    userData: {},
    prevUserData: [],

    list: [],
    searchType: "RECOMMEND_CODE",
    searchText: ""
  };

  // 검색 대상 설정
  searchTypeHandleChange = (value) => {
    this.setState({
      searchType: value
    });
  };

  // 검색 대상 설정
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.geRecommendUserData({ searchText: value });
      }
    );
  };

  // 차트
  getRecommendChart = (params = {}) => {
    let code = params.code;

    httpGet(httpUrl.getRecommendChart, [code], {})
      .then((res) => {
        // console.log(`chart res = ${JSON.stringify(res.data, null, 4)}`);
        this.setState({
          list: res.data
        });
      })
      .catch((e) => {});
  };

  // 마스터 or 검색한 회원정보
  geRecommendUserData = (params = {}) => {
    const { searchType, searchText } = this.state;
    let type = params.searchType || searchType;
    let text = params.searchText || searchText;

    // 검색 allclear조건
    if (text === "") type = "MASTER";
    // console.log(`type = ${JSON.stringify(type, null, 4)}`);
    // console.log(`text = ${JSON.stringify(text, null, 4)}`);

    httpGet(httpUrl.geRecommendUserData, [text, type], {})
      .then((res) => {
        // console.log(`user data res = ${JSON.stringify(res, null, 4)}`);
        this.getRecommendChart({ code: res.data.recommendCode });

        this.setState({
          userData: res.data
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.geRecommendUserData({ searchType: "MASTER" });
  }

  render() {
    const { userData, prevUserData, list, searchType } = this.state;

    const card = (props) => (
      <ChartCard
        data={props.data}
        className={
          userData.id !== props.data.id
            ? (props.data.rightCnt !== 0 || props.data.leftCnt !== 0) &&
              props.click
              ? "pointer"
              : "false"
            : "false"
        }
        onClick={() => {
          if (userData.id !== props.data.id) {
            if (
              (props.data.rightCnt !== 0 || props.data.leftCnt !== 0) &&
              props.click
            ) {
              this.setState({
                prevUserData: [...prevUserData, this.state.userData]
              });
              this.geRecommendUserData({
                searchType: "RECOMMEND_CODE",
                searchText: props.data.recommendCode
              });
            }
          }
        }}
      />
    );

    const loop = (data) =>
      data.map((item) => {
        if (item.childs && item.childs.length)
          return (
            <TreeNode key={item.id} label={card({ data: item, click: false })}>
              {loop(item.childs)}
            </TreeNode>
          );
        return (
          <TreeNode key={item.id} label={card({ data: item, click: true })} />
        );
      });

    return (
      <div style={{ paddingBottom: 20, overflow: "auto" }}>
        <div className="title">조직도</div>
        {/* <div style={{ clear: "both", height: 20 }} /> */}
        <div className="flex-row flex-center flex-sb m-b-20">
          <div className="flex-row flex-center">
            <label className="m-r-10">등급 :</label>
            <div
              className="font-bold font-16 m-r-10"
              style={{ color: color.m }}
            >
              M
            </div>
            <div
              className="font-bold font-16 m-r-10"
              style={{ color: color.m1 }}
            >
              M1
            </div>
            <div
              className="font-bold font-16 m-r-10"
              style={{ color: color.m2 }}
            >
              M2
            </div>
            <div
              className="font-bold font-16 m-r-10"
              style={{ color: color.m4 }}
            >
              M4
            </div>
            <div
              className="font-bold font-16 m-r-10"
              style={{ color: color.gold }}
            >
              GOLD
            </div>
            <div
              className="font-bold font-16 m-r-10"
              style={{ color: color.diamond }}
            >
              DIAMOND
            </div>
            <div className="font-bold font-16" style={{ color: color.penton }}>
              PENTON
            </div>
          </div>
          <div className="flex-row flex-center flex-end">
            <label className="flex-row flex-center m-r-10">
              대상&nbsp;&nbsp;
              <SelectBox
                className="m-r-10"
                style={{ width: "160px" }}
                value={string.orgChartSearchTypeString[searchType]}
                codeString={string.orgChartSearchTypeString}
                onChange={this.searchTypeHandleChange}
              />
            </label>
            <Search
              placeholder={`검색어를 입력해주세요.`}
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: 300 }}
            />
          </div>
        </div>
        <div className="flex-row flex-center m-b-10">
          <label className="m-r-10">아바타 :</label>
          <Icon type="robot" style={{ fontSize: 26, fontWeight: "bold" }} />
        </div>
        <div style={{ clear: "both" }} />
        {prevUserData.length !== 0 && (
          <Icon
            type="left"
            className="m-b-20"
            onClick={() => {
              this.geRecommendUserData({
                searchType: "RECOMMEND_CODE",
                searchText: prevUserData[prevUserData.length - 1].recommendCode
              });
              prevUserData.pop();
            }}
          />
        )}
        <Tree
          className="flex-row flex-center justify-center"
          lineWidth={"1px"}
          lineColor={"#bbc"}
          lineBorderRadius={"20px"}
          key={userData.id}
          label={card({ data: userData })}
          children={loop(list)}
        />
        {/* <Card>{loop(data)}</Card> */}
      </div>
    );
  }
}

export default Chart;
