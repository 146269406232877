import React, { Component } from "react";

import { Input, Pagination } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";
import string from "../../string";
import color from "../../color";
import EventCard from "../../components/event/EventCard";
import SelectBox from "../../components/common/SelectBox";
import EventInfo from "../../components/event/EventInfo";

const Search = Input.Search;
class EventRank extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    searchType: "NAME",
    searchText: "",

    visible: false,
    data: {}
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination;
    this.setState({ pagination: pager });

    this.getEventRank({ pageNum: pagination });
  };

  // 검색 대상 설정
  searchTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value
    });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getEventRank();
      }
    );
  };

  getEventRank = (params = {}) => {
    const { searchType, searchText } = this.state;
    let pageNum = params.pageNum || 1;
    let pageSize = params.pageSize || 50;
    // console.log(`pageNum = ${JSON.stringify(pageNum, null, 4)}`);

    httpGet(
      httpUrl.getEventRank,
      [pageSize, pageNum, searchType, searchText],
      {}
    )
      .then((res) => {
        // console.log(`rank data = ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getEventRank();
  }

  render() {
    const { visible, data, searchType, list, pagination } = this.state;

    return (
      <div>
        {visible && (
          <EventInfo
            visible={visible}
            data={data}
            onCancel={() => {
              this.setState({ visible: false });
            }}
          />
        )}
        <div style={{ clear: "both" }} />
        <div className="title">이번달 순위</div>
        <div style={{ clear: "both" }} />
        <div className="flex-row flex-center m-b-20 flex-end">
          <label htmlFor="searchType" className="m-r-10">
            대상&nbsp;
            <SelectBox
              value={searchType}
              style={{ width: "160px" }}
              code={string.userSearchTypeCode}
              codeString={string.eventSearchTypeString}
              onChange={(value) => {
                if (value !== searchType) {
                  this.searchTypeHandleChange(value);
                }
              }}
            />
          </label>
          <Search
            placeholder={`검색어를 입력해주세요.`}
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: 300 }}
          />
        </div>
        <div className="flex-row flex-center m-b-10">
          <div className="m-r-10" style={{ width: "50%" }}>
            {this.renderLabel()}
            {list.map(
              (item, index) =>
                index < 25 && (
                  <EventCard
                    key={index}
                    rank={item.idx}
                    data={item}
                    onClick={() => {
                      this.setState({
                        visible: true,
                        data: item
                      });
                    }}
                  />
                )
            )}
          </div>
          <div className="m-l-10" style={{ width: "50%" }}>
            {list.length > 25 && this.renderLabel()}
            {list.map(
              (item, index) =>
                index >= 25 &&
                index < 50 && (
                  <EventCard
                    key={index}
                    rank={item.idx}
                    data={item}
                    onClick={() => {
                      this.setState({
                        visible: true,
                        data: item
                      });
                    }}
                  />
                )
            )}
          </div>
        </div>
        <Pagination
          pageSize={50}
          total={pagination.total}
          current={pagination.current}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }

  renderLabel() {
    return (
      <div
        className="flex-row flex-center flex-sa text-center"
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          borderRadius: 5,
          backgroundColor: color.lightgray
        }}
      >
        <div>랭크</div>
        <div style={{ width: 110 }}>레벨</div>
        <div style={{ width: 200 }}>ID </div>
        <div style={{ width: 150 }}>이름</div>
        <div>스코어</div>
        <div>추천</div>
      </div>
    );
  }
}

export default EventRank;
