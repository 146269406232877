const color = {
  white: "#ffffff",
  darkwhite: "#EEEEEE",
  black: "#000000",
  darkgray: "#555555",
  gray: "#737373",
  lightgray: "#CCCCCC",
  red: "#FF2828",
  pink: "#FF4081",
  coral: "#fd585b",
  blue: "#0046ee",
  m: "#464f4a",
  m1: "#0cc44f",
  m2: "#0af2c8",
  m4: "#0a67f2",
  gold: "#bdab06",
  diamond: "#b220d6",
  penton: "#f21d1d"
};

export default color;
