import React, { Component } from "react";

import { Table, Input, Button, Checkbox } from "antd";
import { httpGet, httpPut, httpUrl } from "../../api/httpClient";

import string from "../../string";
import { numberFormat, formatDate } from "../../util";
import SelectBox from "../../components/common/SelectBox";
import DetailModal from "../../components/community/DetailModal";
import { showAlert } from "../../components/common/AlertModal";
import _const from "../../const";
import MultiDetailModal from "../../components/community/MultiDetailModal";

const Search = Input.Search;
class Notice extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    deleted: false,
    categoryType: "ALL",
    searchText: "",

    isLoaded: false,

    edit: false,
    createVisible: false,
    visible: false,
    detailData: {},
    checked: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getNoticeList({ pageNum: pager.current });
    }
  };

  // 삭제 내역 포함 여부 설정
  deletedHandleChange = (e) => {
    this.setState({
      deleted: e.target.checked,
      isLoaded: true
    });
  };

  // 카테고리 종류 설정
  categoryTypeHandleChange = (value) => {
    this.setState({
      categoryType: value,
      isLoaded: true
    });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: true
      },
      () => {
        this.getNoticeList();
      }
    );
  };

  // 삭제
  onDelete = (value, row) => {
    const { list, deleted, pagination } = this.state;

    let pageNum =
      row.deleted === 0 && deleted === false
        ? list.length === 1
          ? pagination.current === 1
            ? pagination.current
            : pagination.current - 1
          : pagination.current
        : pagination.current;

    if (
      global.confirm(
        `${row.title.find((item) => item.language === "ko").content}을(를) ${
          row.deleted ? "복구" : "삭제"
        }하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.noticeDelete, [row.idx], {})
        .then((result) => {
          row.deleted ? showAlert("restore") : showAlert("delete");

          this.getNoticeList({ pageNum: pageNum });
        })
        .catch((error) => {});
    }
  };

  // 공지사항 목록
  getNoticeList = (params = {}) => {
    const { deleted, categoryType, searchText } = this.state;

    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(
      httpUrl.noticeList,
      [10, pageNum, deleted, categoryType, searchText],
      {}
    )
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.noticeList,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getNoticeList();
  }

  render() {
    const { visible, detailData, edit } = this.state;
    const noticeColumns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "구분",
        dataIndex: "category",
        className: "text-center",
        render: (data) => <div>{string.noticeString[data]}</div>
      },
      {
        title: "제목",
        dataIndex: "title",
        className: "text-center",
        render: (data, row) => {
          return (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  visible: true,
                  detailData: row,
                  edit: true
                });
              }}
            >
              {data.find((item) => item.language === "ko").content}
            </div>
          );
        }
      },
      {
        title: "메인 노출",
        dataIndex: "main",
        className: "text-center",
        render: (data) => <div>{data === 1 ? "노출" : "-"}</div>
      },
      {
        width: 300,
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            <div>
              <SelectBox
                value={string.deleteString[data]}
                code={string.toggleCode}
                codeString={string.deleteString}
                onChange={(value) => {
                  if (parseInt(value) !== row.deleted) {
                    this.onDelete(value, row);
                  }
                }}
              />
            </div>
          )
      }
    ];

    return (
      <div>
        {/* <div>{JSON.stringify(this.props)}</div> */}
        <div className="title">공지 관리</div>
        <div className="wrapper mt-35 width-1240" />
        {visible &&
          (_const.isMultiLanguage ? (
            <MultiDetailModal
              edit={edit}
              type="notice"
              visible={visible}
              modalData={detailData}
              onCancel={() => {
                this.setState({ visible: false });
              }}
              onOk={this.getNoticeList}
            />
          ) : (
            <DetailModal
              edit={edit}
              type="notice"
              visible={visible}
              modalData={detailData}
              onCancel={() => {
                this.setState({ visible: false });
              }}
              onOk={this.getNoticeList}
            />
          ))}
        <div style={{ float: "left" }}>
          <Button
            onClick={() => {
              this.setState({ visible: true, edit: false });
            }}
            style={{ marginBottom: "10px" }}
          >
            생성
          </Button>
        </div>
        <div style={{ marginBottom: "10px", float: "right" }}>
          <label htmlFor="">
            카테고리&nbsp;&nbsp;
            <SelectBox
              value={string.noticeString[this.state.categoryType]}
              code={string.noticeCode}
              codeString={string.noticeString}
              onChange={(value) => {
                if (value !== this.state.categoryType)
                  this.categoryTypeHandleChange(value);
              }}
              style={{ width: "160px", marginRight: "10px" }}
            />
          </label>
          <label htmlFor="">
            검색조건&nbsp;&nbsp;&nbsp;
            <Checkbox
              style={{ marginRight: "10px" }}
              onChange={this.deletedHandleChange}
            >
              삭제 포함
            </Checkbox>
          </label>
          <Search
            placeholder="제목, 내용을 입력하세요"
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: "300px" }}
          />
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="notice-table"
          rowKey={(record) => record.idx}
          columns={noticeColumns}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default Notice;
