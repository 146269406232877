import React, { Component } from "react";

import { Modal, Form, Button, Input, InputNumber } from "antd";
import { httpPost, httpUrl } from "../../api/httpClient";

import { checkCoinName, coinDivideFormat, coinMultiFormat } from "../../util";
import { showAlert } from "../common/AlertModal";
import { ShowErrorAlert } from "../common/ErrorAlertModal";

const FormItem = Form.Item;
const PentonWalletAirdrop = Form.create()(
  class extends Component {
    state = {};

    onSubmit = (e) => {
      const { form, data } = this.props;
      let createData = form.getFieldsValue();

      createData = { ...createData, type: data.type, coinType: data.coinType };
      if (createData.amount) {
        createData = {
          ...createData,
          amount: coinMultiFormat(createData.amount, data.coinType)
        };
      }
      if (!createData.memo) {
        createData = { ...createData, memo: null };
      }

      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          // console.log(`createDate = ${JSON.stringify(createData, null, 4)}`);
          if (global.confirm("출금을 하시겠습니까?")) {
            httpPost(httpUrl.pentWalletAirdrop, [], createData)
              .then((res) => {
                // console.log(`res = ${JSON.stringify(res, null, 4)}`);
                if (res.data.result !== "FAIL") {
                  showAlert("money");
                  this.props.form.resetFields();
                  this.props.onCancel();
                } else ShowErrorAlert(res.data.data);
              })
              .catch((error) => {});
          }
        }
      });
    };

    componentDidMount() {
      // console.log(`this.props = ${JSON.stringify(this.props, null, 4)}`);
    }

    render() {
      const { form, visible, onCancel, data } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 8 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };
      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0
          },
          sm: {
            span: 16,
            offset: 8
          }
        }
      };

      return (
        <Modal
          title="출금"
          visible={visible}
          onCancel={onCancel}
          footer={false}
          style={{ minWidth: 800 }}
        >
          <Form
            {...formItemLayout}
            onSubmit={this.onSubmit}
            className="flex-center"
          >
            {/* <FormItem label={<span></span>} colon={false}></FormItem> */}
            <FormItem label={<span>coinType&nbsp;</span>}>
              {checkCoinName(data.coinType)}
            </FormItem>
            <FormItem label={<span>address&nbsp;</span>}>
              {getFieldDecorator("to", {
                rules: [
                  {
                    required: true,
                    message: "주소를 입력해주세요!"
                  }
                ]
              })(
                <Input
                  allowClear={true}
                  style={{ width: "360px" }}
                  placeholder="주소를 입력해주세요"
                />
              )}
            </FormItem>
            <FormItem label={<span>amount&nbsp;</span>}>
              {getFieldDecorator("amount", {
                rules: [
                  {
                    required: true,
                    message: "amount를 입력해주세요!"
                  },
                  {
                    validator: (rule, value, cb) => {
                      if (
                        value &&
                        parseInt(value) >
                          coinDivideFormat(data.balance, data.coinType)
                      ) {
                        cb("입력하신 금액이 너무 큽니다");
                      }
                      cb();
                    }
                  }
                ]
              })(
                <InputNumber
                  allowClear
                  min={0}
                  max={data.balance}
                  placeholder="수량을 입력해주세요."
                  formatter={(value) =>
                    `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  style={{ width: "360px" }}
                />
              )}
            </FormItem>
            <FormItem label={<span>메모&nbsp;</span>}>
              {getFieldDecorator("memo", {
                rules: [
                  {
                    required: false,
                    message: "메모를 입력해주세요!"
                  }
                ]
              })(
                <Input.TextArea
                  placeholder="메모를 입력해주세요"
                  allowClear={true}
                  style={{ width: "360px", minHeight: "30px" }}
                />
              )}
            </FormItem>
            <Form.Item {...tailFormItemLayout}>
              <Button className="m-r-10" onClick={onCancel}>
                취소
              </Button>
              <Button onClick={this.onSubmit}>보내기</Button>
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

export default PentonWalletAirdrop;
