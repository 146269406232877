import React, { Component } from "react";

import { Card } from "antd";

import icon from "./approximation.png";
import {
  checkCoinSymbol,
  checkData,
  coinDivideFormat,
  formatDate,
  numberFormat
} from "../../util";

class SwapCard extends Component {
  state = {};

  componentDidMount() {
    // console.log(`this.props = ${JSON.stringify(this.props, null, 4)}`);
  }

  render() {
    const { data } = this.props;

    return (
      <Card
        style={{
          border: "1px solid",
          borderColor:
            data.status === "SUCCESS"
              ? "blue"
              : data.status === "PENDING"
              ? "green"
              : data.status === "REQUEST"
              ? "orange"
              : data.status === "FAIL"
              ? "red"
              : "#A6A6A6",
          borderRadius: 20
        }}
      >
        {data.status ? (
          <div className="flex-row flex-center flex-sb m-b-10">
            <div
              style={{
                color:
                  data.status === "SUCCESS"
                    ? "blue"
                    : data.status === "PENDING"
                    ? "green"
                    : data.status === "REQUEST"
                    ? "orange"
                    : data.status === "FAIL"
                    ? "red"
                    : "#A6A6A6"
              }}
            >
              {data.status}
            </div>
            <div>{formatDate(data.date)}</div>
          </div>
        ) : (
          <div style={{ clear: "both", height: 30 }} />
        )}
        <div className="flex-row flex-center m-b-10">
          <div className="m-r-10">
            {coinDivideFormat(data.amount, data.coinType)}{" "}
            <b>{checkCoinSymbol(data.coinType)}</b>
          </div>
          <div>(&nbsp;</div>
          <img
            src={icon}
            alt="symbolImage"
            style={{ width: "14px", marginRight: 5 }}
          />
          <div>
            {coinDivideFormat(data.currency * data.amount, data.coinType)}{" "}
            /&nbsp;
          </div>
          <div>{numberFormat(data.currency)} 원</div>
          <div>&nbsp;)</div>
        </div>
        <div className="text-left flex-row">
          TX_ID : &nbsp;
          <span
            className="text-ellipsis"
            style={{
              width: "300px",
              whiteSpace: "normal"
            }}
          >
            {checkData(data.txId)}
          </span>
        </div>
      </Card>
    );
  }
}

export default SwapCard;
