import React, { Component } from "react";

class StatisticsDiv extends Component {
  render() {
    const { style, title, data } = this.props;

    return (
      <div className="flex-row flex-center flex-sa m-b-10">
        <div
          className="font"
          style={{ width: 150, textAlign: "right", ...style }}
        >
          {title}
        </div>
        <div className="font" style={{ width: 200, textAlign: "right" }}>
          {data}
        </div>
      </div>
    );
  }
}

export default StatisticsDiv;
