import React, { Component } from "react";

import { Button, DatePicker, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";

import { httpGet, httpUrl } from "../../api/httpClient";
import {
  formatDate,
  amountDivide,
  endDateFormat,
  startDateFormat
} from "../../util";
import UserModal from "../../components/user/UserModal";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import ShareDetail from "../../components/share/ShareDetail";

const RangePicker = DatePicker.RangePicker;
class ShareLog extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    startDate: "",
    endDate: "",
    periodType: "WEEK"
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getShareLog({ pageNum: pager.current });
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : ""
    });
  };

  // 주기 선택
  handleChangePeriodType = (value) => {
    this.setState({
      periodType: value,
      isLoaded: true
    });
  };

  //
  getShareLog = (params = {}) => {
    const { startDate, endDate, periodType } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getShareLog,
      [10, pageNum, startDate, endDate, periodType],
      {}
    )
      .then((res) => {
        // console.log(`res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getShareLog();
  }

  render() {
    const {
      visible,
      detailVisible,
      type,
      rowData,
      periodType,
      list,
      pagination
    } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        key: "idx",
        title: "날짜",
        dataIndex: "logDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "건수",
        dataIndex: "regCount",
        className: "text-center",
        render: (data, row) =>
          data === 0 ? (
            data
          ) : (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  detailVisible: true,
                  rowData: row
                });
              }}
            >
              {data}
            </div>
          )
      },
      {
        title: "총 수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data) => <div>{amountDivide(data)}</div>
      },
      {
        title: "공유_G (명 / 개)",
        dataIndex: "goldAmount",
        className: "text-center",
        render: (data, row) =>
          data === 0 ? (
            <div>
              {row.goldCount} / {amountDivide(data)}
            </div>
          ) : (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  visible: true,
                  type: "GOLD",
                  rowData: row
                });
              }}
            >
              {row.goldCount} / {amountDivide(data)}
            </div>
          )
      },
      {
        title: "공유_D (명 / 개)",
        dataIndex: "diaAmount",
        className: "text-center",
        render: (data, row) =>
          data === 0 ? (
            <div>
              {row.diaCount} / {amountDivide(data)}
            </div>
          ) : (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  visible: true,
                  type: "DIAMOND",
                  rowData: row
                });
              }}
            >
              {row.diaCount} / {amountDivide(data)}
            </div>
          )
      },
      {
        title: "공유_P (명 / 개)",
        dataIndex: "pntAmount",
        className: "text-center",
        render: (data, row) =>
          data === 0 ? (
            <div>
              {row.pntCount} / {amountDivide(data)}
            </div>
          ) : (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  visible: true,
                  type: "PENTON",
                  rowData: row
                });
              }}
            >
              {row.pntCount} / {amountDivide(data)}
            </div>
          )
      }
    ];

    return (
      <div>
        {visible && (
          <UserModal
            page="share"
            type={type}
            rowData={rowData}
            visible={visible}
            periodType={periodType}
            onCancel={() => {
              this.setState({ visible: false });
            }}
          />
        )}
        {detailVisible && (
          <ShareDetail
            page="share"
            rowData={rowData}
            visible={detailVisible}
            periodType={periodType}
            onCancel={() => {
              this.setState({ detailVisible: false });
            }}
          />
        )}
        <div className="title">공유 내역</div>
        <div className="flex-row flex-center m-b-10 flex-end">
          <label className="flex-row flex-center m-r-10">
            주기&nbsp;&nbsp;
            <SelectBox
              className="m-r-10"
              style={{ width: "160px" }}
              value={string.shareStatistics[periodType]}
              codeString={string.shareStatistics}
              onChange={this.handleChangePeriodType}
            />
          </label>
          <label>
            검색기간&nbsp;
            <RangePicker
              locale={locale}
              format={dateFormat}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
            <Button
              onClick={() => {
                this.getShareLog();
              }}
            >
              검색
            </Button>
          </label>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default ShareLog;
