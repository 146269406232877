import React, { Component } from "react";

import { Icon } from "antd";

import color from "../../color";
import { numberFormat } from "../../util";
import UserLevelColor from "../user/UserLevelColor";

class EventCard extends Component {
  render() {
    const { onClick, rank, data } = this.props;
    return (
      <div
        onClick={data.count !== 0 ? onClick : null}
        className={data.count !== 0 ? "pointer" : "false"}
        style={{
          border: "1px solid",
          borderColor: color.lightgray,
          borderRadius: 5,
          paddingTop: 10,
          paddingBottom: 10
        }}
      >
        <div className="flex-row flex-center text-center flex-sa">
          <div style={{ minWidth: 20 }}>{numberFormat(rank)}</div>
          <UserLevelColor
            className="flex-center"
            data={data}
            style={{
              width: 110,
              padding: 10,
              border: "1px solid",
              borderRadius: 5
              // minWidth: 60
            }}
          />
          <div style={{ width: 200 }}>{data.id}</div>
          <div style={{ width: 150 }}>
            <div>{data.name}</div>
            <div>({data.recommendCode})</div>
          </div>
          <div style={{ minWidth: 20 }}>{numberFormat(data.score)}</div>
          <div style={{ minWidth: 20 }}>
            <Icon type="like" style={{ marginRight: 5 }} />
            {numberFormat(data.count)}
          </div>
        </div>
      </div>
    );
  }
}

export default EventCard;
