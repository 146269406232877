import React, { Component } from "react";

import { Modal, Table } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";
import { numberFormat } from "../../util";
import UserLevelColor from "../user/UserLevelColor";

class EventInfo extends Component {
  state = {
    list: [],
    pagination: {
      current: 0,
      total: 0
    }
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getEventInfo({ pageNum: pager.current });
  };

  getEventInfo = (params = {}) => {
    const { data } = this.props;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getEventInfo, [10, pageNum, data.recommendCode], {})
      .then((res) => {
        // console.log(`event info res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    // console.log(`this.props = ${JSON.stringify(this.props, null, 4)}`);
    this.getEventInfo();
  }

  render() {
    const { visible, onCancel } = this.props;
    const { list, pagination } = this.state;

    const columns = [
      {
        width: 100,
        key: "user.idx",
        title: "순번",
        dataIndex: "user.idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 400,
        title: "ID",
        dataIndex: "user.id",
        className: "text-center"
      },
      {
        title: "이름",
        dataIndex: "user.name",
        className: "text-center",
        render: (data, row) => (
          <div className="flex-row flex-center justify-center font-bold">
            <UserLevelColor
              className="m-r-10"
              data={row.user}
              style={{
                padding: 10,
                border: "1px solid",
                borderRadius: 5,
                width: 110
              }}
            />
            <div style={{ width: 150 }}>
              {data}
              <div>({row.user.recommendCode})</div>
            </div>
          </div>
        )
      }
    ];

    return (
      <Modal
        visible={visible}
        title="추천목록"
        onCancel={onCancel}
        style={{ minWidth: "1000px" }}
        footer={null}
      >
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.user.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}

export default EventInfo;
