import React, { Component } from "react";
import { Badge, Icon } from "antd";

import string from "../../string";
import color from "../../color";

class UserLevelColor extends Component {
  render() {
    const { className, style, data } = this.props;

    return (
      <div
        className={className}
        style={{
          ...style,
          color:
            data.level === -1
              ? color.lightgray
              : data.level === 0
              ? color.m
              : data.level === 1
              ? color.m1
              : data.level === 2
              ? color.m2
              : data.level === 3
              ? color.m4
              : data.level === 4
              ? color.gold
              : data.level === 5
              ? color.diamond
              : color.penton
        }}
      >
        {data.avatar === 0 ? (
          string.userLevelCode[data.level]
        ) : (
          <Badge
            count={
              <Icon
                type="robot"
                style={{
                  color:
                    data.level === -1
                      ? color.lightgray
                      : data.level === 0
                      ? color.m
                      : data.level === 1
                      ? color.m1
                      : data.level === 2
                      ? color.m2
                      : data.level === 3
                      ? color.m4
                      : data.level === 4
                      ? color.gold
                      : data.level === 5
                      ? color.diamond
                      : color.penton
                }}
              />
            }
          >
            <div style={{ padding: 10 }}>
              {string.userLevelCode[data.level]}
            </div>
          </Badge>
        )}
      </div>
    );
  }
}

export default UserLevelColor;
