import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "antd";
import {
  TotalStatistics,
  MemberList,
  RollupLog,
  Chart,
  PentonWallet,
  PentonWalletRequest,
  ShareLog,
  HoldLog,
  SwapLog,
  LevelStatistics,
  RollupStatistics,
  RecRollupStatistics,
  VirtualAccountDeposit,
  EventLogList,
  EventRank,
  FeeWallet,
  CoinBalance,
  Distribute,
  Manual,
  LockManual,
  DecentralFoundationList,
  DecentralDailyLock,
  WithDrawRequestLog,
  TransferLog,
  Notice,
  Faq,
  Admin,
  MyInfo
} from "../pages";

export default class Content extends Component {
  render() {
    return (
      <Layout.Content style={{ margin: "24px 16px 0" }}>
        <div
          style={{
            padding: 24,
            background: "#fff",
            minHeight: "82vh",
            minWidth: "1280px"
          }}
        >
          <Switch>
            {/* 대시보드 */}
            <Route path="/dashBoard" component={TotalStatistics} />
            {/* 회원 */}
            <Route exact path="/user/memberList" component={MemberList} />
            {/* 조직도 */}
            <Route path="/recommendChart" component={Chart} />
            {/* penton 지갑 */}
            <Route path="/pentonWallet" component={PentonWallet} />
            <Route
              path="/pentonWalletRequest"
              component={PentonWalletRequest}
            />
            {/* 롤업내역 */}
            <Route path="/rollupLog" component={RollupLog} />
            {/* 공유내역 */}
            <Route path="/shareLog" component={ShareLog} />
            {/* 보류내역 */}
            <Route path="/holdLog" component={HoldLog} />
            {/* 스왑내역 */}
            <Route path="/ethSwapLog" component={SwapLog} />
            {/* 통계 */}
            <Route path="/statistics/level" component={LevelStatistics} />
            <Route path="/statistics/rollup" component={RollupStatistics} />
            <Route
              path="/statistics/recRollup"
              component={RecRollupStatistics}
            />
            <Route
              path="/statistics/virtualAccountDeposit"
              component={VirtualAccountDeposit}
            />
            {/* 이벤트 */}
            <Route path="/event/log" component={EventLogList} />
            <Route path="/event/rank" component={EventRank} />

            {/* 지갑 */}
            <Route path="/wallet/feeWallet" component={FeeWallet} />
            <Route path="/wallet/coinBalance" component={CoinBalance} />
            {/* 코인 */}
            <Route path="/coin/distribute" component={Distribute} />
            <Route path="/coin/manual" component={Manual} />
            <Route path="/coin/lockManual" component={LockManual} />
            {/* 재단 */}
            <Route
              path="/decentral/foundationList"
              component={DecentralFoundationList}
            />
            <Route path="/decentral/dailyLock" component={DecentralDailyLock} />
            {/* 거래 */}
            <Route
              path="/transaction/withDrawRequestLog"
              component={WithDrawRequestLog}
            />
            <Route path="/transaction/transferLog" component={TransferLog} />
            {/* 게시글 */}
            <Route path="/community/notice" component={Notice} />
            <Route path="/community/faq" component={Faq} />
            {/* 시스템 */}
            <Route path="/system/admin" component={Admin} />
            {/* 정보수정 */}
            <Route path="/mypage/myinfo" component={MyInfo} />
          </Switch>
        </div>
      </Layout.Content>
    );
  }
}
