import React, { Component } from "react";

import { Button, Card, DatePicker, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";

import { httpGet, httpUrl } from "../../api/httpClient";

import { amountDivide, dayFormat, numberFormat, statFormat } from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import RollupCard from "../../components/statistics/RollupCard";

const RangePicker = DatePicker.RangePicker;
class RollupStatistics extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    periodType: "DAY",
    startDate: "",
    endDate: ""
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getStatisticsRollupList({ pageNum: pager.current });
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? statFormat(date[0]) : "",
      endDate: date.length !== 0 ? statFormat(date[1]) : ""
    });
  };

  // 주기 선택
  handleChangePeriodType = (value) => {
    this.setState({
      periodType: value
    });
  };

  // 현황 목록
  getStatisticsRollupList = (params = {}) => {
    const { periodType, startDate, endDate } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getStatisticsRollupList,
      [10, pageNum, periodType, startDate, endDate],
      {}
    )
      .then((res) => {
        // console.log(`res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  getStatisticsRollup = () => {
    httpGet(httpUrl.getStatisticsRollup, [], {})
      .then((res) => {
        // console.log(`rollup res = ${JSON.stringify(res, null, 4)}`);
        this.setState({
          count: res.data.count,
          amount: res.data.amount,
          todayCount: res.data.todayCount,
          todayAmount: res.data.todayAmount
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getStatisticsRollup();
    this.getStatisticsRollupList();
  }

  render() {
    const {
      count,
      amount,
      todayCount,
      todayAmount,
      periodType,
      list,
      pagination
    } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        key: "statDate",
        title: "날짜",
        dataIndex: "statDate",
        className: "text-center",
        render: (data) => dayFormat(data)
      },
      {
        title: "롤업 건수",
        dataIndex: "count",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "롤업 수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data) => <div>{amountDivide(data)}</div>
      },
      {
        title: "지급보류 건수",
        dataIndex: "sideCount",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "지급보류 수량",
        dataIndex: "sideAmount",
        className: "text-center",
        render: (data) => <div>{amountDivide(data)}</div>
      },
      {
        title: "합계 건수",
        dataIndex: "totalCount",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "합계 수량",
        dataIndex: "totalAmount",
        className: "text-center",
        render: (data) => <div>{amountDivide(data)}</div>
      }
    ];

    return (
      <div>
        {/* 롤업 대쉬보드 */}
        <Card
          className="flex-row flex-center m-b-10"
          style={{
            border: "0px dotted #A6A6A6",
            borderRadius: 20
          }}
        >
          <RollupCard
            countTitle="총 건수"
            count={count}
            amountTitle="총 수량"
            amount={amount}
          />
          <RollupCard
            countTitle="오늘 건수"
            count={todayCount}
            amountTitle="오늘 수량"
            amount={todayAmount}
          />
        </Card>

        <div className="title">롤업 현황</div>
        <div className="flex-row flex-center m-b-10 flex-end">
          <label className="flex-row flex-center m-r-10">
            주기&nbsp;&nbsp;
            <SelectBox
              className="m-r-10"
              style={{ width: "160px" }}
              value={string.partnerStatistics[periodType]}
              codeString={string.partnerStatistics}
              onChange={this.handleChangePeriodType}
            />
          </label>
          <label>
            검색기간&nbsp;
            <RangePicker
              locale={locale}
              format={dateFormat}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
            <Button
              onClick={() => {
                this.getStatisticsRollupList();
              }}
            >
              검색
            </Button>
          </label>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default RollupStatistics;
