import React, { Component } from "react";

import { Modal, Table } from "antd";
import {
  formatDate,
  checkCoinName,
  coinDivideFormat,
  formatDateSecond
} from "../../util";
import { httpGet, httpUrl } from "../../api/httpClient";

class ShareDetail extends Component {
  state = {
    list: [],
    pagination: {
      current: 0,
      total: 0
    }
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getShareDetailList({ pageNum: pager.current });
  };

  getShareDetailList = (params = {}) => {
    const { rowData, periodType } = this.props;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.shareDetailList,
      [10, pageNum, rowData.idx, formatDateSecond(rowData.logDate), periodType],
      {}
    )
      .then((res) => {
        // console.log(`share detail res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    // console.log(`this.props = ${JSON.stringify(this.props, null, 4)}`);
    this.getShareDetailList();
  }

  render() {
    const { visible, onCancel } = this.props;
    const { list, pagination } = this.state;

    const columns = [
      {
        key: "createData",
        title: "날짜",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data, row) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "이름",
        dataIndex: "name",
        className: "text-center"
      },
      {
        title: "FROM",
        dataIndex: "from",
        className: "text-center",
        render: (data, row) => (
          <div>
            {row.id} ({data})
          </div>
        )
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => <div>{coinDivideFormat(data, row.coinType)}</div>
      }
    ];

    return (
      <Modal
        visible={visible}
        title="목록"
        onCancel={onCancel}
        style={{ minWidth: "1000px" }}
        footer={null}
      >
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}

export default ShareDetail;
