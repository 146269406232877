import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Table, Button, Input, Select } from "antd";

import { httpGet, httpUrl, httpPut } from "../../api/httpClient";
import { numberFormat, formatDate, checkData } from "../../util";
import string from "../../string";

import SelectBox from "../../components/common/SelectBox";
import { showAlert } from "../../components/common/AlertModal";
import UserInfo from "../../components/user/UserInfo";
import UserDetail from "../../components/user/UserDetail";
import OrganizationChart from "../../components/user/OrganizationChart";

const Search = Input.Search;
const Option = Select.Option;
class MemberList extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    level: "",
    searchType: "ID",
    searchText: "",

    visible: false,

    chartVisible: false
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getUserList({ pageNum: pager.current });
    }
  };

  // 검색 대상 설정
  searchTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value,
      isLoaded: true
    });
  };

  // 등급 검색 설정
  userLevelHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      level: value,
      isLoaded: true
    });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: true
      },
      () => {
        this.getUserList();
      }
    );
  };

  // 보류
  onHold = (data) => {
    if (
      global.confirm(`${data.hold === 0 ? "보류" : "보류 취소"}하시겠습니까?`)
    )
      httpPut(httpUrl.userHold, [data.user.recommendCode], {})
        .then((res) => {
          // console.log(`data= ${JSON.stringify(res, null, 4)}`);
          data.hold === 0 ? showAlert("hold") : showAlert("unhold");
          this.getUserList({ pageNum: this.state.pagination.current });
        })
        .catch((error) => {});
  };

  // 회원 정보 리스트
  getUserList = (params = {}) => {
    const { searchText, searchType, level } = this.state;
    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;
    // console.log(`pageNum = ${JSON.stringify(pageNum, null, 4)}`);
    // console.log(`searchText = ${JSON.stringify(searchText, null, 4)}`);
    // console.log(`searchType= ${JSON.stringify(searchType, null, 4)}`);
    // console.log(`level= ${JSON.stringify(level, null, 4)}`);

    httpGet(
      httpUrl.getUserList,
      [10, pageNum, searchText, searchType, level],
      {}
    )
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    const userInfo = reactLocalStorage.getObject("adminUser");
    if (userInfo.authList) {
      this.getUserList();
    }
    // console.log(global.lanList);
  }

  render() {
    const {
      visible,
      type,
      chartVisible,
      userData,
      level,
      searchType,
      list,
      pagination
    } = this.state;

    // 추천인과 후원인은 동일 인물이 가능
    const columns = [
      {
        key: "user.idx",
        title: "순번",
        dataIndex: "user.idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "ID",
        dataIndex: "user.id",
        className: "text-center"
      },
      {
        title: "이름",
        dataIndex: "user.name",
        className: "text-center",
        render: (data, row) => (
          <UserInfo
            data={row.user}
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState({
                visible: true,
                userData: row.user,
                type: "user"
              });
            }}
          />
        )
      },
      {
        title: "추천인",
        dataIndex: "recommender.name",
        className: "text-center",
        render: (data, row) =>
          row.recommender === null ? (
            checkData(row.recommender)
          ) : (
            <UserInfo
              data={row.recommender}
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  visible: true,
                  userData: row.recommender,
                  type: "recommend"
                });
              }}
            />
          )
      },
      {
        title: "후원인",
        dataIndex: "patron.name",
        className: "text-center",
        render: (data, row) =>
          row.patron === null || data === null ? (
            checkData(data) || "-"
          ) : (
            <UserInfo
              data={row.patron}
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  visible: true,
                  userData: row.patron,
                  type: "recommend"
                });
              }}
            />
          )
      },
      {
        title: "조직도",
        dataIndex: "chart",
        className: "text-center",
        render: (data, row) => (
          <Button
            disabled={row.user.level === -1 ? true : false}
            onClick={() => {
              this.setState({ chartVisible: true, userData: row.user });
            }}
          >
            조직도
          </Button>
        )
      },
      {
        title: "보류",
        dataIndex: "hold",
        className: "text-center",
        render: (data, row) => (
          <Button
            disabled={row.user.level === -1 ? true : false}
            onClick={() => {
              // console.log(`item = ${item}`);
              this.onHold(row);
            }}
          >
            {data === 0 ? "활성화" : "보류"}
          </Button>
        )
      },
      {
        title: "날짜",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      }
    ];

    return (
      <div className="flex-row flex-column">
        {/* 회원정보 */}
        {visible && (
          <UserDetail
            visible={visible}
            userData={userData}
            type={type}
            page="user"
            onCancel={() => {
              this.setState({
                visible: false
              });
            }}
            refresh={this.getUserList}
          />
        )}
        {/* 조직도 */}
        {chartVisible && (
          <OrganizationChart
            visible={chartVisible}
            userData={userData}
            onCancel={() => {
              this.setState({
                chartVisible: false
              });
            }}
          />
        )}
        <div className="title">회원 관리</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <label htmlFor="searchType" className="m-r-10">
            등급&nbsp;
            <Select
              style={{ width: "160px" }}
              value={level}
              onChange={(value) => {
                if (value !== level) {
                  this.userLevelHandleChange(value);
                }
              }}
            >
              {string.userLevel.map((item) => {
                return (
                  <Option key={`${item}`} value={item}>
                    {string.userLevelCode[item]}
                  </Option>
                );
              })}
            </Select>
          </label>
          <label htmlFor="searchType" className="m-r-10">
            검색 대상&nbsp;
            <SelectBox
              value={searchType}
              style={{ width: "160px" }}
              code={string.userSearchTypeCode}
              codeString={string.userSreachTypeString}
              onChange={(value) => {
                if (value !== searchType) {
                  this.searchTypeHandleChange(value);
                }
              }}
            />
          </label>
          <Search
            placeholder={`검색어를 입력해주세요.`}
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: 300 }}
          />
        </div>
        <Table
          rowKey={(record) => record.user.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default MemberList;
