import React, { Component } from "react";
import fileDownload from "js-file-download";

import { Button, Checkbox, Modal, Table } from "antd";
import { httpGet, httpUrl, httpDownload } from "../../api/httpClient";
import {
  amountFormat,
  endDateFormat,
  formatDate,
  formatDateSecond,
  numberFormat,
  checkData,
  checkCoinName
} from "../../util";
import moment from "moment";

class P2PLogModal extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    deleted: false,
    expire: false,

    isLoaded: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getP2PLogList({ pageNum: pagination.current });
    }
  };

  // 삭제 포함 여부 선택
  deletedHandleChange = (e) => {
    this.setState({
      deleted: e.target.checked,
      isLoaded: true
    });
  };

  // 마감 포함 여부 선택
  expireHandleChange = (e) => {
    this.setState({
      expire: e.target.checked,
      isLoaded: true
    });
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    let { isLoaded, pagination, deleted, expire } = this.state;

    if (isLoaded) alert("검색 후 가능합니다.");
    else {
      let total = pagination.total <= 9 ? 10 : pagination.total;

      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?"))
        httpDownload(
          httpUrl.getTransferP2PLogListExcel,
          [total, 1, this.props.logIdx, deleted, expire],
          {}
        )
          .then((res) => {
            fileDownload(res, `LOCK 내역.xlsx`);
          })
          .catch((e) => {
            alert(`다운로드 실패`);
          });
    }
  };

  // p2p 내역 목록 조회
  getP2PLogList = (params = {}) => {
    const { deleted, expire } = this.state;
    let pageNum = params.pageNum || 1;

    let url =
      this.props.type === "user"
        ? httpUrl.getP2PLogList
        : httpUrl.getTransferP2PLogList;

    httpGet(url, [10, pageNum, this.props.logIdx, deleted, expire], {})
      .then((res) => {
        // console.log(`data = ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getP2PLogList();
  }

  render() {
    const { visible, onCancel, type } = this.props;
    const { list, pagination } = this.state;

    const columns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "마감일",
        dataIndex: "expireDate",
        className: "text-center",
        render: (data, row, index) => <div>{formatDate(data)}</div>
      },
      {
        title: "ID",
        dataIndex: "id",
        className: "text-center",
        render: (data) => <div>{data}</div>
      },
      {
        title: "주소",
        dataIndex: "address",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row, index) => (
          <span>
            {data === -1 ? "전체잠금" : amountFormat(data, row.coinType)}
          </span>
        )
      },
      {
        width: 200,
        title: "메모",
        dataIndex: "memo",
        className: "text-center",
        render: (data) => (
          <div style={{ wordWrap: "break-word", width: "200px" }}>
            {checkData(data)}
          </div>
        )
      },
      {
        width: 200,
        title: "삭제 메모",
        dataIndex: "memoDeleted",
        className: "text-center",
        render: (data, row, index) => (
          <div style={{ wordWrap: "break-word", width: "200px" }}>
            {row.deleted !== null && <span>{checkData(data)}</span>}
          </div>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render: (data) => <div>{data === 1 ? "삭제" : "-"}</div>
      }
    ];

    return (
      <Modal
        visible={visible}
        title="LOCK 내역"
        onCancel={onCancel}
        style={{ minWidth: "1800px" }}
        footer={null}
      >
        <div
          className={
            type === "user"
              ? "flex-row flex-center flex-end m-b-10"
              : "flex-row flex-center flex-sb m-b-10"
          }
        >
          {type !== "user" && (
            <Button
              onClick={this.downloadExcel}
              disabled={list.length === 0 ? true : false}
            >
              엑셀 다운로드
            </Button>
          )}
          <div>
            <label htmlFor="" style={{ marginRight: "10px" }}>
              검색조건&nbsp;&nbsp;&nbsp;
              <Checkbox onChange={this.deletedHandleChange}>삭제 포함</Checkbox>
              <Checkbox onChange={this.expireHandleChange}>만료 포함</Checkbox>
            </label>
            <Button
              onClick={() => {
                this.getP2PLogList();
              }}
            >
              검색
            </Button>
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) => {
            // console.log(moment().endOf("day" - 1));
            return endDateFormat(record.expireDate) <=
              formatDateSecond(
                moment()
                  .endOf("day" - 1)
                  .toString()
              ) || record.deleted === 1
              ? "gray"
              : "";
          }}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}

export default P2PLogModal;
