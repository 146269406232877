import React, { Component } from "react";

import { Icon } from "antd";

import color from "../../color";
import string from "../../string";
import { numberFormat } from "../../util";

class UserLevelCountColor extends Component {
  render() {
    const { style, level, data } = this.props;
    return (
      <div className="level-title" style={style}>
        <div
          style={{
            fontSize: 18,
            color:
              level === 0
                ? color.m
                : level === 1
                ? color.m1
                : level === 2
                ? color.m2
                : level === 3
                ? color.m4
                : level === 4
                ? color.gold
                : level === 5
                ? color.diamond
                : color.penton
          }}
        >
          {string.userLevelCode[level]}
        </div>
        <div
          className="flex-row flex-center justify-center"
          style={{
            fontSize: 26,
            color:
              level === 0
                ? color.m
                : level === 1
                ? color.m1
                : level === 2
                ? color.m2
                : level === 3
                ? color.m4
                : level === 4
                ? color.gold
                : level === 5
                ? color.diamond
                : color.penton
          }}
        >
          <Icon type="user" style={{ marginRight: 5 }} />
          {numberFormat(data.count)}
          {data.avatar !== 0 && (
            <div className=" m-l-10" style={{ fontSize: 16 }}>
              (<Icon type="robot" style={{ marginRight: 5 }} />
              {numberFormat(data.avatar)})
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default UserLevelCountColor;
