import React, { Component } from "react";

import { Button, Card } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";

import string from "../../string";
import { amountFormat, checkCoinName } from "../../util";
import PentonWalletAirdrop from "../../components/pntWallet/PentonWalletAirdrop";

class PentonWallet extends Component {
  state = {
    list: [],

    visible: false,
    walletData: {}
  };

  getPentonWalletList = () => {
    httpGet(httpUrl.getPentonWalletList, [], {})
      .then((res) => {
        // console.log(`pentwallet res = ${JSON.stringify(res, null, 4)}`);
        this.setState({ list: res.data });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getPentonWalletList();
  }

  render() {
    const { visible, walletData, list } = this.state;

    return (
      <div>
        {visible && (
          <PentonWalletAirdrop
            visible={visible}
            data={walletData}
            onCancel={() => {
              this.setState({ visible: false });
            }}
          />
        )}
        <div className="title">지갑 관리</div>
        <div style={{ clear: "both" }} />
        {list.map((item) => {
          return (
            <Card.Grid
              hoverable={false}
              className="flex-center justify-center m-b-10 m-l-10"
              style={{
                padding: 10,
                minWidth: "49%",
                minHeight: "350px",
                border: "2px solid #A6A6A6"
              }}
            >
              <div
                className="text-left justify-center level-title"
                style={{
                  padding: 20,
                  borderBottom: "2px solid #A6A6A6"
                }}
              >
                {string.pntWalletType[item.type]}
              </div>
              {item.list.map((data) => (
                <div
                  className="flex-row flex-center flex-sb text-center"
                  style={{
                    padding: 20,
                    borderBottom: "1px solid #A6A6A6"
                  }}
                >
                  <div style={{ minWidth: "100px" }}>
                    {checkCoinName(data.coinType)}
                  </div>
                  <div style={{ minWidth: "200px" }}>{data.address}</div>
                  <div style={{ minWidth: "100px" }}>
                    {amountFormat(data.balance, data.coinType)}
                  </div>
                  <Button
                    onClick={() => {
                      this.setState({ visible: true, walletData: data });
                    }}
                  >
                    출금
                  </Button>
                </div>
              ))}
              <div style={{ clear: "both", height: 10 }} />
            </Card.Grid>
          );
        })}
        <div style={{ clear: "both" }} />
      </div>
    );
  }
}

export default PentonWallet;
