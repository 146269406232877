import React, { Component } from "react";

import { Icon } from "antd";

import { checkData } from "../../util";

class UserLevelCount extends Component {
  componentDidMount() {}

  render() {
    const { onClick, onClickAvatar, className, data } = this.props;
    return (
      <div className={`flex-row flex-center justify-center`}>
        {data.count === 0 ? (
          checkData(data.count)
        ) : (
          <div onClick={onClick} className={className}>
            {data.count}
          </div>
        )}
        {data.avatar !== 0 && (
          <div
            className={`${className} m-l-10`}
            style={{ fontSize: 14 }}
            onClick={onClickAvatar}
          >
            (<Icon type="robot" style={{ marginRight: 5 }} />
            {data.avatar})
          </div>
        )}
      </div>
    );
  }
}

export default UserLevelCount;
