import React, { Component } from "react";

import { Modal, Descriptions, Tabs, Table, Button, Input } from "antd";

import { httpUrl, httpGet, httpPut } from "../../api/httpClient";
import {
  amountDivide,
  amountFormat,
  checkCoinName,
  checkData,
  formatDate,
  numberFormat
} from "../../util";
import string from "../../string";
import { showAlert } from "../common/AlertModal";

import UserInfo from "./UserInfo";
import RollupLogSelect from "../rollup/RollupLogSelect";
import RollupLogHoldSelect from "../rollup/RollupLogHoldSelect";

const { TabPane } = Tabs;
const Ditems = Descriptions.Item;
class UserDetail extends Component {
  state = {
    data: {},
    isUpdateFlag: false,

    walletList: [],
    walletPagination: {
      total: 0,
      current: 0
    },
    rewardData: []
  };

  onChangeModal = (value, type) => {
    var data = this.state.data;
    data[type] = value;
    this.setState({
      data
    });
  };

  onUpdate = () => {
    const { data } = this.state;

    if (global.confirm(`수정하시겠습니까?`))
      httpPut(httpUrl.userUpdate, [], data)
        .then((res) => {
          // console.log(`res = ${JSON.stringify(res, null, 4)}`);
          if (res.result === "SUCCESS") {
            showAlert("update");
            this.setState({ isUpdateFlag: false });
            this.props.refresh({ refresh: true });
          }
        })
        .catch((error) => {});
  };

  // 회원 정보 api
  getUserDetail = () => {
    const { page, userData } = this.props;
    let url = "";
    if (page === "user") url = httpUrl.userSelect;
    if (page === "rollup") url = httpUrl.rollupUser;
    if (page === "swap") url = httpUrl.swapUserSelect;

    httpGet(url, [userData.id], {})
      .then((res) => {
        // console.log(`user detail res = ${JSON.stringify(res, null, 4)}`);
        this.setState({ data: res.data });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
    this.getUserDetail();
    this.getUserWalletList();
    this.getUserRewardLog();
    this.getUserHoldLog();
    this.getUserRecommendList();
  }

  render() {
    const { visible, type, onCancel, page } = this.props;
    const { data, isUpdateFlag } = this.state;

    return (
      <Modal
        visible={visible}
        title={`${
          type === "user" ? "회원" : type === "recommend" ? "추천인" : "후원인"
        } 정보`}
        onCancel={onCancel}
        footer={null}
        style={{ minWidth: "1400px" }}
      >
        {/* 회원 정보 */}
        <Descriptions
          className="m-b-10"
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        >
          <Ditems label="생성일" span={2}>
            {formatDate(data.createDate)}
          </Ditems>
          <Ditems label="아이디(추천코드)">{`${data.id} (${data.recommendCode})`}</Ditems>
          <Ditems label="level">{string.userLevelCode[data.level]}</Ditems>
          <Ditems label="이름" className="flex-row flex-center">
            <div className="flex-row flex-center flex-sb">
              {!isUpdateFlag ? (
                checkData(data.name)
              ) : (
                <Input
                  style={{ width: 200 }}
                  value={data.name}
                  placeholder="홍길동"
                  onChange={(e) => {
                    this.onChangeModal(e.target.value, "name");
                  }}
                />
              )}
              {page === "user" && this.renderUpdate()}
            </div>
          </Ditems>
          <Ditems label="휴대폰">{checkData(data.phone)}</Ditems>
          <Ditems label="추천인(추천코드)">
            {`${checkData(data.recommenderName)} (${data.recommender})`}
          </Ditems>
          <Ditems label="후원인(추천코드)">
            {data.patronName === null
              ? checkData(data.patronName)
              : `${data.patronName} (${data.patron})`}
          </Ditems>
          <Ditems label="펜톤주소">{data.address}</Ditems>
          <Ditems label="가상 펜톤 주소">{data.vitualAdress}</Ditems>
          <Ditems label="메모">{data.memo}</Ditems>
        </Descriptions>
        <div style={{ clear: "both", height: 10 }} />
        <Tabs defaultActiveKey="1" onChange={() => {}}>
          <TabPane tab="지갑" key="1">
            {/* 회원 지갑 정보 */}
            {this.renderWalletList()}
          </TabPane>
          <TabPane tab="지급 내역" key="2">
            {/* 회원 지급 내역 */}
            {this.renderRewardLog()}
          </TabPane>
          <TabPane tab="보류 내역" key="3">
            {/* 회원 보류 내역 */}
            {this.renderHoldLog()}
          </TabPane>
          <TabPane tab="직추내역" key="4">
            {/* 회원 직추 내역 */}
            {this.renderRecommendLog()}
          </TabPane>
        </Tabs>
      </Modal>
    );
  }

  renderUpdate() {
    const { isUpdateFlag } = this.state;
    return !isUpdateFlag ? (
      <Button
        className="m-l-10"
        onClick={() => {
          this.setState({ isUpdateFlag: true });
        }}
      >
        수정하기
      </Button>
    ) : (
      <div className="flex-row flex-center m-l-10">
        <Button
          className="m-r-10"
          onClick={() => {
            this.onUpdate();
          }}
        >
          수정
        </Button>
        <Button
          onClick={() => {
            this.setState({ isUpdateFlag: false });
          }}
        >
          취소
        </Button>
      </div>
    );
  }

  // 회원 지갑 페이징
  handleWalletTableChange = (walletPagination, filters, sorter) => {
    const pager = { ...this.state.walletPagination };
    pager.current = walletPagination.current;
    this.setState({
      walletPagination: pager
    });

    this.getUserWalletList({ pageNum: pager.current });
  };

  // 회원 지갑 정보 api
  getUserWalletList = (params = {}) => {
    const { page, userData } = this.props;
    let pageNum = params.pageNum || 1;

    let url = "";
    if (page === "user") url = httpUrl.getUserWalletList;
    if (page === "rollup") url = httpUrl.getRollupUserWalletList;
    if (page === "swap") url = httpUrl.getSwapWalletList;

    httpGet(url, [10, pageNum, userData.idx], {})
      .then((res) => {
        // console.log(`user wallet List = ${JSON.stringify(res.data, null, 4)}`);
        const walletPagination = { ...this.state.walletPagination };
        walletPagination.current = res.data.currentPage;
        walletPagination.total = res.data.totalCount;
        this.setState({
          walletList: res.data.walletList,
          walletPagination
        });
      })
      .catch((e) => {});
  };

  // 회원 지갑
  renderWalletList() {
    const { walletList, walletPagination } = this.state;

    const colums = [
      {
        title: "생성일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        key: "coinType",
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "지갑",
        dataIndex: "address",
        className: "text-center"
      },
      {
        title: "잔액",
        dataIndex: "balance",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      }
    ];

    return (
      <Table
        rowKey={(record) => record.coinType}
        columns={colums}
        dataSource={walletList}
        pagination={walletPagination}
        onChange={this.handleWalletTableChange}
        style={{ marginBottom: "10px" }}
      />
    );
  }

  // 회원 지급내역 api
  getUserRewardLog = () => {
    const { page, userData } = this.props;

    let url = "";
    if (page === "user") url = httpUrl.getUserRewardLog;
    if (page === "rollup") url = httpUrl.getRollupUserRewardLog;
    if (page === "swap") url = httpUrl.getSwapRewardLog;

    httpGet(url, [userData.recommendCode], {})
      .then((res) => {
        console.log(`user reward = ${JSON.stringify(res.data, null, 4)}`);
        this.setState({
          rewardData: [res.data]
        });
      })
      .catch((e) => {});
  };

  // 회원 지급내역 -> To가 회원인 경우
  renderRewardLog() {
    const { rewardData, data, rewardVisible, rewardType } = this.state;

    const colums = [
      {
        title: () => (
          <div>
            <div>롤업</div>
            <div>(건수 / 수량)</div>
          </div>
        ),
        dataIndex: "rollupAmount",
        className: "text-center",
        render: (data, row) =>
          data === 0 ? (
            <div>
              {row.rollupCount} / {amountDivide(data)}
            </div>
          ) : (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  rewardVisible: true,
                  rewardType: "ROLL_UP"
                });
              }}
            >
              {row.rollupCount} / {amountDivide(data)}
            </div>
          )
      },
      {
        title: () => (
          <div>
            <div>추천 롤업</div>
            <div>(건수 / 수량)</div>
          </div>
        ),
        dataIndex: "recRollupAmount",
        className: "text-center",
        render: (data, row) =>
          data === 0 ? (
            <div>
              {row.recRollupCount} / {amountDivide(data)}
            </div>
          ) : (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  rewardVisible: true,
                  rewardType: "REC_ROLL_UP"
                });
              }}
            >
              {row.recRollupCount} / {amountDivide(data)}
            </div>
          )
      },
      {
        title: () => (
          <div>
            <div>추천</div>
            <div>(건수 / 수량)</div>
          </div>
        ),
        dataIndex: "recommendAmount",
        className: "text-center",
        render: (data, row) =>
          data === 0 ? (
            <div>
              {row.recommendCount} / {amountDivide(data)}
            </div>
          ) : (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  rewardVisible: true,
                  rewardType: "RECOMMEND"
                });
              }}
            >
              {row.recommendCount} / {amountDivide(data)}
            </div>
          )
      },
      {
        title: () => (
          <div>
            <div>공유</div>
            <div>(건수 / 수량)</div>
          </div>
        ),
        dataIndex: "shareAmount",
        className: "text-center",
        render: (data, row) =>
          data === 0 ? (
            <div>
              {row.shareCount} / {amountDivide(data)}
            </div>
          ) : (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  rewardVisible: true,
                  rewardType: "SHARE"
                });
              }}
            >
              {row.shareCount} / {amountDivide(data)}
            </div>
          )
      },
      {
        title: "합계",
        dataIndex: "amount",
        className: "text-center",
        render: (data) => <div>{amountDivide(data)}</div>
      }
    ];

    return (
      <div>
        {rewardVisible && (
          <RollupLogSelect
            pageType={"user"}
            page={this.props.page}
            visible={rewardVisible}
            recommendCode={data.recommendCode}
            type={rewardType}
            onCancel={() => {
              this.setState({ rewardVisible: false });
            }}
          />
        )}
        <Table
          rowKey={(record) => record.amount}
          columns={colums}
          dataSource={rewardData}
          pagination={false}
          style={{ marginBottom: "10px" }}
        />
      </div>
    );
  }

  // 회원 보류내역 api
  getUserHoldLog = () => {
    const { page, userData } = this.props;

    let url = "";
    if (page === "user") url = httpUrl.getUserHoldLog;
    if (page === "rollup") url = httpUrl.getRollupUserHoldLog;
    if (page === "swap") url = httpUrl.getSwapHoldLog;

    httpGet(url, [userData.id], {})
      .then((res) => {
        // console.log(`hold user detail res = ${JSON.stringify(res, null, 4)}`);
        this.setState({
          holdList: [res.data]
        });
      })
      .catch((e) => {});
  };

  // 회원 보류내역
  renderHoldLog() {
    const { holdVisible, recommendCode, rewardType, userHold, holdList } =
      this.state;

    const column = [
      {
        title: () => (
          <div>
            <div>롤업</div>
            <div>(건수 / 수량)</div>
          </div>
        ),
        dataIndex: "rollupAmount",
        className: "text-center",
        render: (data, row) =>
          data === 0 ? (
            <div>
              {row.rollupCount} / {amountDivide(data)}
            </div>
          ) : (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  holdVisible: true,
                  recommendCode: row.recommendCode,
                  rewardType: "ROLL_UP",
                  userHold: "NORMAL"
                });
              }}
            >
              {row.rollupCount} / {amountDivide(data)}
            </div>
          )
      },
      {
        title: () => (
          <div>
            <div>추천 롤업</div>
            <div>(건수 / 수량)</div>
          </div>
        ),
        dataIndex: "recRollupAmount",
        className: "text-center",
        render: (data, row) =>
          data === 0 ? (
            <div>
              {row.recRollupCount} / {amountDivide(data)}
            </div>
          ) : (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  holdVisible: true,
                  recommendCode: row.recommendCode,
                  rewardType: "REC_ROLL_UP",
                  userHold: "NORMAL"
                });
              }}
            >
              {row.recRollupCount} / {amountDivide(data)}
            </div>
          )
      },
      {
        title: () => (
          <div>
            <div>지급제외</div>
            <div>(건수 / 수량)</div>
          </div>
        ),
        dataIndex: "holdAmount",
        className: "text-center",
        render: (data, row) =>
          data === 0 ? (
            <div>
              {row.holdCount} / {amountDivide(data)}
            </div>
          ) : (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  holdVisible: true,
                  recommendCode: row.recommendCode,
                  rewardType: "ALL",
                  userHold: "HOLD"
                });
              }}
            >
              {row.holdCount} / {amountDivide(data)}
            </div>
          )
      },
      {
        key: "amount",
        title: "합계",
        dataIndex: "amount",
        className: "text-center",
        render: (data) => <div>{amountDivide(data)}</div>
      }
    ];

    return (
      <div>
        {holdVisible && (
          <RollupLogHoldSelect
            pageType="user"
            page={this.props.page}
            visible={holdVisible}
            recommendCode={recommendCode}
            type={rewardType}
            hold={userHold}
            onCancel={() => {
              this.setState({ holdVisible: false });
            }}
          />
        )}
        <Table
          rowKey={(record) => record.recommendCode}
          columns={column}
          dataSource={holdList}
          pagination={false}
        />
      </div>
    );
  }

  // 회원 직추 페이징
  handleRecommendTableChange = (recommendPagination, filters, sorter) => {
    const pager = { ...this.state.recommendPagination };
    pager.current = recommendPagination.current;
    this.setState({
      recommendPagination: pager
    });

    this.getUserRecommendList({ pageNum: pager.current });
  };

  // 회원 직추 api - asc
  getUserRecommendList = (params = {}) => {
    const { page, userData } = this.props;
    let pageNum = params.pageNum || 1;

    let url = "";
    if (page === "user") url = httpUrl.getUserRecommendList;
    if (page === "rollup") url = httpUrl.getRollupUserRecommendList;
    if (page === "swap") url = httpUrl.getSwapRecommendList;

    httpGet(url, [10, pageNum, userData.recommendCode], {})
      .then((res) => {
        // console.log(`user recommend List = ${JSON.stringify(res.data, null, 4)}`);
        const recommendPagination = { ...this.state.recommendPagination };
        recommendPagination.current = res.data.currentPage;
        recommendPagination.total = res.data.totalCount;
        this.setState({
          recommendList: res.data.list,
          recommendPagination
        });
      })
      .catch((e) => {});
  };

  // 회원 직추
  renderRecommendLog() {
    const { recommendList, recommendPagination } = this.state;

    const columns = [
      {
        key: "user.idx",
        title: "순번",
        dataIndex: "user.idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        key: "user.id",
        title: "ID",
        dataIndex: "user.id",
        className: "text-center"
      },
      {
        title: "이름",
        dataIndex: "user.name",
        className: "text-center",
        render: (data, row) => <UserInfo data={row.user} />
      },
      {
        title: "추천인",
        dataIndex: "recommender.name",
        className: "text-center",
        render: (data, row) =>
          row.recommender === null ? (
            checkData(row.recommender)
          ) : (
            <UserInfo data={row.recommender} />
          )
      },
      {
        title: "후원인",
        dataIndex: "patron.name",
        className: "text-center",
        render: (data, row) =>
          row.patron === null || data === null ? (
            checkData(data) || "-"
          ) : (
            <UserInfo data={row.patron} />
          )
      }
    ];

    return (
      <Table
        rowKey={(record) => record.user.idx}
        columns={columns}
        dataSource={recommendList}
        pagination={recommendPagination}
        onChange={this.handleRecommendTableChange}
      />
    );
  }
}

export default UserDetail;
