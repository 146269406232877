import React, { Component } from "react";

import { Modal, Table } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import { checkData, formatDate, numberFormat } from "../../util";
import UserInfo from "./UserInfo";

class UserModal extends Component {
  state = {
    list: [],
    pagination: {
      current: 0,
      total: 0
    }
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.props.page === "share"
      ? this.getShareLevelUserList({ pageNum: pager.current })
      : this.getStatisticsUserSelectList({ pageNum: pager.current });
  };

  // 공유 등급 회원 목록 조회
  getShareLevelUserList = (params = {}) => {
    const { rowData, type } = this.props;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.shareLevelUserList, [10, pageNum, rowData.idx, type], {})
      .then((res) => {
        // console.log(`share level res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  // 통계
  getStatisticsUserSelectList = (params = {}) => {
    const { periodType, type, statDate, avatar } = this.props;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getStatisticsUserSelectList,
      [10, pageNum, periodType, type, statDate, avatar],
      {}
    )
      .then((res) => {
        // console.log(`statistics res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
    const { page } = this.props;
    page === "share"
      ? this.getShareLevelUserList()
      : this.getStatisticsUserSelectList();
  }

  render() {
    const { visible, onCancel } = this.props;
    const { list, pagination } = this.state;

    const columns = [
      {
        key: "user.idx",
        title: "순번",
        dataIndex: "user.idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "날짜",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "ID",
        dataIndex: "user.id",
        className: "text-center"
      },
      {
        title: "이름",
        dataIndex: "user.name",
        className: "text-center",
        render: (data, row) => <UserInfo data={row.user} />
      },
      {
        title: "추천인",
        dataIndex: "recommender.name",
        className: "text-center",
        render: (data, row) =>
          row.recommender === null ? (
            checkData(row.recommender)
          ) : (
            <UserInfo data={row.recommender} />
          )
      },
      {
        title: "후원인",
        dataIndex: "patron.name",
        className: "text-center",
        render: (data, row) =>
          row.patron === null || data === null ? (
            checkData(data) || "-"
          ) : (
            <UserInfo data={row.patron} />
          )
      }
    ];

    return (
      <Modal
        visible={visible}
        title="회원목록"
        onCancel={onCancel}
        style={{ minWidth: "1500px" }}
        footer={null}
      >
        <div style={{ clear: "both" }} />
        <Table
          id="member-table"
          className="flex-1"
          rowKey={(record) => record.user.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}

export default UserModal;
