import React, { Component } from "react";

import color from "../../color";
import UserLevelColor from "./UserLevelColor";

class UserInfo extends Component {
  render() {
    const { onClick, style, data } = this.props;

    return (
      <div
        className="flex-row flex-center justify-center"
        onClick={onClick}
        style={style}
      >
        <UserLevelColor
          className="m-r-20"
          data={data}
          style={{
            padding: 10,
            border: "1px solid",
            borderRadius: 5,
            minWidth: 60
          }}
        />
        <div style={{ width: 180 }}>
          <div className="font-bold">
            {data.name}&nbsp;({data.recommendCode})
          </div>
          <div className="flex-row flex-center justify-center flex-sb">
            <div
              className="text-left"
              style={{ color: color.gray, marginRight: 10 }}
            >
              <div>산하</div>
              <div>직추</div>
            </div>
            <div className="text-right font-12" style={{ color: color.black }}>
              <div>
                {data.leftCnt} | {data.rightCnt}
              </div>
              <div>
                {data.recLeftCnt} | {data.recRightCnt}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserInfo;
