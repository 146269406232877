import React, { Component } from "react";

import { Button, Table } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";

import {
  formatDate,
  numberFormat,
  checkCoinName,
  checkData,
  coinDivideFormat
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";

class PentonWalletRequest extends Component {
  state = {
    list: [],
    pagination: { current: 0, total: 0 },
    walletType: "ALL"
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getPentonWalletRequestList({ pageNum: pager.current });
  };

  // 검색 대상 설정
  walletTypeHandleChange = (value) => {
    // console.log(`walletType = ${JSON.stringify(value)}`);
    this.setState({
      walletType: value
    });
  };

  getPentonWalletRequestList = (params = {}) => {
    const { walletType } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getPentonWalletRequestList, [10, pageNum, walletType], {})
      .then((res) => {
        // console.log(`pentwallet request res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getPentonWalletRequestList();
  }

  render() {
    const { walletType, list, pagination } = this.state;

    const columns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "출금일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "FROM",
        dataIndex: "from",
        className: "text-center",
        render: (data) => <div>{string.pntWalletType[data]}</div>
      },
      {
        width: 250,
        title: "TO",
        dataIndex: "to",
        className: "text-center",
        render: (data) => (
          <div
            className="text-ellipsis"
            style={{ width: "250px", whiteSpace: "normal" }}
          >
            {checkData(data)}
          </div>
        )
      },
      {
        title: "금액",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => <div>{coinDivideFormat(data, row.coinType)}</div>
      },
      {
        width: 250,
        title: "TX_ID",
        dataIndex: "trxId",
        className: "text-center",
        render: (data) => (
          <div
            className="text-ellipsis"
            style={{ width: "250px", whiteSpace: "normal" }}
          >
            {checkData(data)}
          </div>
        )
      },
      {
        width: 250,
        title: "MEMO",
        dataIndex: "memo",
        className: "text-center",
        render: (data) => (
          <div
            className="text-ellipsis"
            style={{ width: "250px", whiteSpace: "normal" }}
          >
            {checkData(data)}
          </div>
        )
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.requestLogString[data]}</div>
      }
    ];

    return (
      <div>
        <div className="title">출금 내역</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <label htmlFor="walletType" className="m-r-10">
            FROM&nbsp;
            <SelectBox
              value={walletType}
              style={{ width: "160px" }}
              code={string.pntWalletTypeCode}
              codeString={string.pntWalletType}
              onChange={(value) => {
                if (value !== walletType) {
                  this.walletTypeHandleChange(value);
                }
              }}
            />
          </label>
          <Button onClick={this.getPentonWalletRequestList}>검색</Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default PentonWalletRequest;
