import React, { Component } from "react";

import { Icon } from "antd";

import color from "../../color";
import { numberFormat } from "../../util";

class UserTotalCountColor extends Component {
  componentDidMount() {
    // console.log(`this.props = ${JSON.stringify(this.props, null, 4)}`);
  }

  render() {
    const { style, title, data } = this.props;
    return (
      <div className="level-title" style={{ ...style, color: color.darkgray }}>
        <label style={{ fontSize: 18 }}>{title}</label>
        <div
          className="flex-row flex-center justify-center"
          style={{ fontSize: 26 }}
        >
          <Icon type="user" style={{ marginRight: 5 }} />
          {numberFormat(data.count)}
          {data.avatar !== 0 && (
            <div className=" m-l-10" style={{ fontSize: 16 }}>
              (<Icon type="robot" style={{ marginRight: 5 }} />
              {numberFormat(data.avatar)})
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default UserTotalCountColor;
