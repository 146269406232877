export default {
  // serverProtocol: "http",
  // serverIp: "localhost",
  // serverPort: "58545",
  // isFoundationInFee: false,  // 플랫타 구분
  // isPurchaseInFee: false,    // 구매 구분
  // isP2PFlag: false           //  p2p 구분
  // isLogoFlag: false,         // 로고 이미지
  // isOrganizationFlag: false  // 조직 구분
  // isPreviewFlag: false       // 공지 미리보기 조건

  /* test */
  // serverProtocol: "http",
  // serverPort: "58546",
  // clientPort: "15002",
  /* prod */
  serverProtocol: "https",
  serverPort: "48545",
  clientPort: "15001",

  // test
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "test.back.blobs.info",
  // clientId: "r06yvi25vl",   // naver map client key
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: false, // 로고 이미지
  // isOrganizationFlag: true,
  // isP2PFlag: false,
  // isPreviewFlag: false

  // PNT-chain wallet
  isFoundationInFee: false,
  isPurchaseInFee: false,
  serverIp: "back.penton-chain.org",
  // naver map client key
  clientId: "r06yvi25vl",
  clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  isLogoFlag: false, // 로고 이미지
  logoImage: require("../src/img/logo-drnt.png"),
  logoImageWhite: require("../src/img/logo-drnt-white.png"),
  isOrganizationFlag: false,
  isP2PFlag: false,
  isPreviewFlag: false,
  isDecentralFlag: true // 거래현황에서 LOCK확인


  // STZ wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "sback.penton-chain.org",
  // // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: false, // 로고 이미지
  // logoImage: require("../src/img/logo-drnt.png"),
  // logoImageWhite: require("../src/img/logo-drnt-white.png"),
  // isOrganizationFlag: false,
  // isP2PFlag: false,
  // isPreviewFlag: false,
  // isDecentralFlag: true // 거래현황에서 LOCK확인
};
